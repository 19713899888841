.unit-features-v1 {
	.feature-group {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-radius: var(--border-radius);
		border: 1px solid var(--color-grey-dark);
		background-color: var(--color-grey-light);


		@media(max-width:767px) {
			flex-wrap: wrap;
			padding: 15px;
		}

		@media(min-width:768px) {
			padding-top: 15px;
		}

		.ul-nopints {
			max-width: 100%;
			margin-bottom: 0;
			display: flex;
			flex-wrap: wrap;

			@media(max-width:767px) {
				max-width: 100%;
			}

			li {
				display: none;
				text-align: center;
				font-size: 14px;
				margin-bottom: 5px;

				&:nth-child(-n+10) {
					display: inline-block;
				}

				@media(min-width:768px) {
					width: 33%;
					margin-bottom: 15px;
				}

				@media(min-width:992px) {
					width: 20%;
					margin-bottom: 15px;
				}


				@media(max-width:767px) {

					margin-right: 5px;
					width: 45%;
					text-align: left;
				}

				@media(max-width:560px) {
					font-size: 14px;
				}


				&.full-width {
					@media(max-width:767px) {
						width: 100%;
					}
				}

				i {
					@media (min-width:768px) {
						font-size: 25px;
						width: 100%;
						text-align: center;
						margin-bottom: 6px;
					}

					&.text-green {
						color: var(--color-secondary);
					}

					@media(max-width:767px) {
						/*margin-right: 5px;*/
						width: 25px;
					}
				}

				.fa-stack {
					@media (min-width:768px) {
						margin: 0 auto;
						display: block;
						margin-bottom: 3px;
					}

					@media(max-width:767px) {
						width: 16px;
						left: -6px;
					}
				}
			}
		}

		.rating {
			i {
				color: var(--color-secondary);
			}
		}
	}

	.fa-water {
		height: 18px;
		text-align: center;

		@media(max-width:767px) {
			height: 11px;
			text-align: center;
		}

		&:before {
			content: "";
			background-size: cover;
			height: 19px;
			width: 29px;
			position: absolute;
			left: 0;
			top: 4px;
			right: 0;
			margin: 0 auto;

			@media(max-width:767px) {
				height: 12px;
				width: 18px;
				left: 0;
				top: 0;
				right: auto;
			}
		}
	}
}