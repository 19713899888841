.static-view {
	.inner-banner {
		&.contact {
			background-image: url(RESOURCE/img/banner_contact.webp);
		}

		&.faq {
			background-image: url(RESOURCE/img/banner_faq.webp);
		}



	}
}